import React from "react";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router-dom";
import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";
import useSortableData from "../../../hooks/useSortableData";
import useTable from "../../../hooks/useTable";
import { useState } from "react";
import { useEffect } from "react";
import AdminListService from "../../../services/admin-list.service";
import Select from "react-select";
import * as XLSX from "xlsx";

import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { quizSearch } from "../../../schema";
import { toast } from "react-toastify";

export default function ManageRegisteredUsers() {
  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken } = state;
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [tableData, settableData] = useState([]);
  const [limit, setLimit] = useState("20");
  const [currentPage, setCurrentPage] = useState(1);

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [locations, setLocations] = useState([
    // {
    //   value: "3",
    //   label: "Baxter",
    // },
    // {
    //   value: "4",
    //   label: "Duluth",
    // },
    // {
    //   value: "5",
    //   label: "Elk River",
    // },
    // {
    //   value: "6",
    //   label: "Fridley",
    // },
    // {
    //   value: "1",
    //   label: "Grand Forks",
    // },
    // {
    //   value: "2",
    //   label: "Mason City",
    // },
    // {
    //   value: "7",
    //   label: "Medford",
    // },
    // {
    //   value: "8",
    //   label: "North Branch",
    // },
    // {
    //   value: "9",
    //   label: "Redwood Falls",
    // },
    // {
    //   value: "10",
    //   label: "Saint Cloud",
    // },
    // {
    //   value: "11",
    //   label: "Shakopee",
    // },
    // {
    //   value: "12",
    //   label: "Sioux City",
    // },
    // {
    //   value: "13",
    //   label: "Sioux Falls",
    // },
    // {
    //   value: "14",
    //   label: "Spirit Lake",
    // },
    // {
    //   value: "15",
    //   label: "West Fargo",
    // },

    {
      value: "16",
      label: "07 - Sioux Falls C",
    },
    {
      value: "17",
      label: "08 - Sioux Falls N",
    },
    {
      value: "18",
      label: "23 - Medford",
    },
    {
      value: "19",
      label: "25 - North Branch",
    },
    {
      value: "20",
      label: "30 - Spirit lake",
    },
    {
      value: "21",
      label: "38 - Mason City",
    },
    {
      value: "22",
      label: "41 - West Fargo",
    },
    {
      value: "23",
      label: "42 - Grand Forks",
    },
    {
      value: "24",
      label: "53 - Shakopee",
    },
    {
      value: "25",
      label: "57 - Elk River",
    },
    {
      value: "26",
      label: "58 - St Cloud",
    },
    {
      value: "27",
      label: "62 - Fridley",
    },
    {
      value: "28",
      label: "66 - Baxter",
    },
    {
      value: "30",
      label: "68 - Duluth",
    },
    {
      value: "29",
      label: "73 - Sioux City",
    },
    {
      value: "31",
      label: "78 - Cedar Falls",
    },
  ]);

  const adminList = async (limit, page) => {
    setLoading(true);
    try {
      const responce = await AdminListService.listRegisteredUsers(limit, page);

      if (responce.status === 200) {
        const res = responce.data.data.data;
        const results = [];
        res.map((value) => {
          return results.push({
            store_location: value.store_location,

            customerEmail: value.cust_email,
            customer_F: value.cust_fname,
            customer_L: value.cust_lname,
            employee_F: value.sales_fname,
            employee_L: value.sales_lname,
            phone: value.phone,
            preferred_charity: value.preferred_charity,
            comments: value.comments,
            question: value.question,
            recommend: value.recommend,
            date: value.date,
          });
        });

        settableData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total_records);
        setTotalPages(responce.data.data.total_pages);
        setCurrentPage(responce.data.data.current_page);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      settableData([]);

      setLimit("20");
      setTotalResults("0");
      setTotalPages("0");
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      adminList(limit, currentPage);
    }
  }, [adminAccessToken]);

  const searchInTable = async (limit, page, values) => {
    setLoading(true);
    try {
      const responce = await AdminListService.searchRegisteredData(
        limit,
        page,
        values
      );

      if (responce.status === 200) {
        const res = responce.data.data.data;
        const results = [];
        res.map((value) => {
          return results.push({
            store_location: value.store_location,

            customerEmail: value.cust_email,
            customer_F: value.cust_fname,
            customer_L: value.cust_lname,
            employee_F: value.sales_fname,
            employee_L: value.sales_lname,
            phone: value.phone,
            preferred_charity: value.preferred_charity,
            comments: value.comments,
            question: value.question,
            recommend: value.recommend,
            date: value.date,
          });
        });

        settableData([...results]);

        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total_records);
        setTotalPages(responce.data.data.total_pages);
        setCurrentPage(responce.data.data.current_page);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      settableData([]);

      setLimit("20");
      setTotalResults("0");
      setTotalPages("0");
      setCurrentPage(1);
    }
  };

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        fieldtype: "",
        searchval: "",
      },
      validationSchema: quizSearch,
      onSubmit: (values) => {
        searchInTable(limit, "1", values);
      },
    });

  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice } = useTable(data, page, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <div className="table-responsive mt-3">
        <table className="table table-striped table-bordered W-100 sort-ths">
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("date")}
                className={getClassNamesFor("date")}
              >
                No.
              </th>
              <th
                scope="col"
                onClick={() => requestSort("date")}
                className={getClassNamesFor("date")}
              >
                Date
              </th>
              <th
                scope="col"
                onClick={() => requestSort("customer_F")}
                className={getClassNamesFor("customer_F")}
              >
                Customer First Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("customer_L")}
                className={getClassNamesFor("customer_L")}
              >
                Customer Last Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("phone")}
                className={getClassNamesFor("phone")}
              >
                Customer Phone#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("customerEmail")}
                className={getClassNamesFor("customerEmail")}
              >
                Customer Email
              </th>
              <th
                scope="col"
                onClick={() => requestSort("employee_F")}
                className={getClassNamesFor("employee_F")}
              >
                Employee First Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("employee_L")}
                className={getClassNamesFor("employee_L")}
              >
                Employee Last Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("store_location")}
                className={getClassNamesFor("store_location")}
              >
                Store Location
              </th>
              <th
                scope="col"
                onClick={() => requestSort("question")}
                className={getClassNamesFor("question")}
              >
                Mattress Brand
              </th>
              <th
                scope="col"
                onClick={() => requestSort("preferred_charity")}
                className={getClassNamesFor("preferred_charity")}
              >
                Preferred Charity
              </th>
              <th
                scope="col"
                onClick={() => requestSort("recommend")}
                className={getClassNamesFor("recommend")}
              >
                Recommended by RSA
              </th>
              <th
                scope="col"
                onClick={() => requestSort("comments")}
                className={getClassNamesFor("comments")}
              >
                Comments
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.date}</td>
                  <td>{el.customer_F}</td>
                  <td>{el.customer_L}</td>
                  <td>{el.phone}</td>
                  <td>{el.customerEmail}</td>

                  <td>{el.employee_F}</td>
                  <td>{el.employee_L}</td>
                  <td>{el.store_location}</td>
                  <td>{el.question}</td>
                  <td>{el.preferred_charity}</td>
                  <td>{el.recommend}</td>

                  <td>
                    <span
                      style={{
                        display: "block",
                        width: "200px",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      {el.comments}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="11" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              if (values.fieldtype !== "" && values.searchval !== "") {
                searchInTable(limit, e, values);
              } else {
                adminList(limit, e);
              }
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  const exportRecord = async () => {
    setLoading(true);
    try {
      const responce = await AdminListService.exportRegisterUsers(values);
      let resultData = responce.data.data;
      const worksheet = XLSX.utils.json_to_sheet(resultData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, `${responce.data.fileName}`);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        toast.warn(err?.response?.data?.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">
                Registered Users [{totalResults}]
                {totalResults > 0 && (
                  <button
                    type="button"
                    onClick={exportRecord}
                    className="btn btn-primary d-inline-block float-end btn-sm"
                  >
                    Export Record
                  </button>
                )}
              </h5>
            </div>

            <div className="card-body">
              <div className="clearfix"></div>
              <form className="row" onSubmit={handleSubmit} noValidate>
                <div className="col-md-3">
                  <div className="input-field-box mb-lg-0">
                    <label>Show Record</label>
                    <select
                      className="form-select"
                      onChange={(e) => {
                        if (
                          values.fieldtype !== "" &&
                          values.searchval !== ""
                        ) {
                          searchInTable(e.target.value, "1", values);
                        } else {
                          adminList(e.target.value, "1");
                        }
                      }}
                    >
                      <option value="20">20</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                      <option value="1500">1500</option>
                      <option value={totalResults}>All</option>
                    </select>
                  </div>
                </div>

                <div className="col-lg-3 ms-auto">
                  <div className="input-field-box mb-lg-0">
                    <label>Select Column</label>
                    <select
                      className={`form-select ${
                        errors.fieldtype && touched.fieldtype ? "is-danger" : ""
                      }`}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue("searchval", "");
                      }}
                      name="fieldtype"
                      value={values.fieldtype || ""}
                      required
                    >
                      <option value="">Select Column</option>
                      <option value="date">Date</option>
                      <option value="fname">Customer First Name </option>
                      <option value="lname">Customer Last Name </option>
                      <option value="phone">Customer Phone </option>
                      <option value="email">Customer Email </option>
                      <option value="sales_name_f">Employee First Name </option>
                      <option value="sales_name_l">Employee Last Name </option>
                      <option value="store_location">Store Location </option>

                      <option value="question">Mattress Brand </option>
                      <option value="preferred_charity">
                        Preferred Charity{" "}
                      </option>
                      <option value="recommend">Recommended by RSA</option>
                      <option value="comments">Comments</option>
                    </select>
                  </div>
                  {errors.fieldtype && touched.fieldtype ? (
                    <span className="text-danger">{errors.fieldtype}</span>
                  ) : null}
                </div>

                <div className="col-lg-4 ps-lg-0">
                  <div className="input-field-box mb-lg-0">
                    <label>Search</label>
                    <div className="input-group">
                      {values.fieldtype === "store_location" ? (
                        locations.length ? (
                          <Select
                            className="border-0 form-control form-label mb-0 p-0"
                            placeholder={"Store Location"}
                            options={locations}
                            isSearchable={true}
                            isClearable={true}
                            onChange={(selectedOption) => {
                              if (selectedOption) {
                                setFieldValue(
                                  "searchval",
                                  selectedOption?.value
                                );
                              } else {
                                setFieldValue("searchval", "");
                              }
                            }}
                          />
                        ) : null
                      ) : (
                        <input
                          type={values.fieldtype === "date" ? "date" : "text"}
                          className={`form-control ${
                            errors.searchval && touched.searchval
                              ? "is-danger"
                              : ""
                          }`}
                          onChange={handleChange}
                          name="searchval"
                          value={values.searchval || ""}
                          required
                        />
                      )}

                      <button className="btn btn-primary" type="submit">
                        Search
                      </button>
                      <button
                        className="btn btn-primary"
                        type="reset"
                        onClick={() => window.location.reload(false)}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                  {errors.searchval && touched.searchval ? (
                    <span className="text-danger">{errors.searchval}</span>
                  ) : null}
                </div>
              </form>
              <Table data={tableData} rowsPerPage={limit} />
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
