import { React, useRef, useState, useLayoutEffect, useEffect } from "react";

import logo from "../Images/logo.webp";
import cff from "../Images/cff.png";
import makeWish from "../Images/make-a-wish.png";
import localFood from "../Images/local-food.png";

import "./new-register.css";
import "bootstrap/dist/css/bootstrap.min.css";
import secureLocalStorage from "react-secure-storage";

import RegisterService from "../services/register.service";

import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

// import ReCAPTCHA from "react-google-recaptcha";

import $ from "jquery";

import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";

function RegisterForm(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Registration Form";
  const [fields, setFields] = useState({
    name: "",
    lname: "",
    phone: "",
    emailid: "",
    store_location: "",
    sales_name_f: "",
    sales_name_l: "",
    question: "",
    preferred_charity: "",
    comments: "",
    recommend: "",
    receive_email: false,
  });

  const [loading, setLoading] = useState(false);
  const [storeLoading, setStoreLoading] = useState(false);

  const [errors, setErrors] = useState({});

  const [locations] = useState([
    // {
    //   value: "3",
    //   label: "Baxter",
    // },
    // {
    //   value: "4",
    //   label: "Duluth",
    // },
    // {
    //   value: "5",
    //   label: "Elk River",
    // },
    // {
    //   value: "6",
    //   label: "Fridley",
    // },
    // {
    //   value: "1",
    //   label: "Grand Forks",
    // },
    // {
    //   value: "2",
    //   label: "Mason City",
    // },
    // {
    //   value: "7",
    //   label: "Medford",
    // },
    // {
    //   value: "8",
    //   label: "North Branch",
    // },
    // {
    //   value: "9",
    //   label: "Redwood Falls",
    // },
    // {
    //   value: "10",
    //   label: "Saint Cloud",
    // },
    // {
    //   value: "11",
    //   label: "Shakopee",
    // },
    // {
    //   value: "12",
    //   label: "Sioux City",
    // },
    // {
    //   value: "13",
    //   label: "Sioux Falls",
    // },
    // {
    //   value: "14",
    //   label: "Spirit Lake",
    // },
    // {
    //   value: "15",
    //   label: "West Fargo",
    // },
    {
      value: "16",
      label: "07 - Sioux Falls C",
    },
    {
      value: "17",
      label: "08 - Sioux Falls N",
    },
    {
      value: "18",
      label: "23 - Medford",
    },
    {
      value: "19",
      label: "25 - North Branch",
    },
    {
      value: "20",
      label: "30 - Spirit lake",
    },
    {
      value: "21",
      label: "38 - Mason City",
    },
    {
      value: "22",
      label: "41 - West Fargo",
    },
    {
      value: "23",
      label: "42 - Grand Forks",
    },
    {
      value: "24",
      label: "53 - Shakopee",
    },
    {
      value: "25",
      label: "57 - Elk River",
    },
    {
      value: "26",
      label: "58 - St Cloud",
    },
    {
      value: "27",
      label: "62 - Fridley",
    },
    {
      value: "28",
      label: "66 - Baxter",
    },
    {
      value: "30",
      label: "68 - Duluth",
    },
    {
      value: "29",
      label: "73 - Sioux City",
    },
    {
      value: "31",
      label: "78 - Cedar Falls",
    },
  ]);

  const [question] = useState([
    {
      value: "Ashley Sleep",
      label: "Ashley Sleep",
    },
    {
      value: "Beautyrest",
      label: "Beautyrest",
    },
    {
      value: "Purple",
      label: "Purple",
    },
    {
      value: "Restonic",
      label: "Restonic",
    },
    {
      value: "Sealy",
      label: "Sealy",
    },
    {
      value: "Stearns and Foster",
      label: "Stearns and Foster",
    },
    {
      value: "Tempur-Pedic",
      label: "Tempur-Pedic",
    },
  ]);

  const [signoutTime, setSignoutTime] = useState(900000);
  const [value_userform, setValue_userform] = useState([]);

  const navigate = useNavigate();

  secureLocalStorage.removeItem("registerform");

  // useLayoutEffect(() => {
  //   const getLocations = async () => {
  //     try {
  //       setStoreLoading(true);
  //       const response = await RegisterService.getStoreLocation();

  //       var locations = response.data.data;
  //       const results = [];
  //       locations.map((value) => {
  //         return results.push({
  //           value: value.id,
  //           label: value.store_location,
  //         });
  //       });
  //       await setLocations([...results]);
  //       setStoreLoading(false);
  //     } catch (err) {
  //       if (err?.status === 429 || err?.status === "429") {
  //         alert("Max Limit Reached. Please wait.");
  //       }
  //       setStoreLoading(false);
  //     }
  //   };
  //   getLocations();
  // }, []);

  timeout();
  function timeout() {
    setTimeout(function () {
      logout();
    }, signoutTime);
  }

  const logout = () => {
    destroy();
  };

  const destroy = () => {
    localStorage.setItem("returnpage", true);
    localStorage.removeItem("return_page");
    localStorage.removeItem("registerForm");
    localStorage.removeItem("isRegister");
  };

  const ref = useRef();

  const pasted = (e) => {
    e.preventDefault();
    return false;
  };

  const onlyNumbers = (evt) => {
    const { name, value } = evt.target;
    evt.target.value = evt.target.value.replace(/[^0-9]+/g, "");

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: evt.target.value,
      };
    });
  };

  const handleChange = (e) => {
    let { value, name } = e.target;
    if (name === "state" && value === "true") {
      value = "";
    }
    if (name === "store_location" && value === "true") {
      value = "";
    }
    if (name === "receive_email") {
      value = e.target.checked;
    }

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };
  const saveErrors = (err) => {
    setErrors(err);
  };

  const submituserRegistrationForm = (e) => {
    e.preventDefault();
    let data = {
      fname: fields?.name,
      lname: fields?.lname,
      phone: fields?.phone,
      email: fields?.emailid,
      store_location: fields?.store_location,
      sales_name_f: fields?.sales_name_f,
      sales_name_l: fields?.sales_name_l,
      question: fields?.question,
      preferred_charity: fields?.preferred_charity,
      comments: fields?.comments,
      recommend: fields?.recommend,
      receive_email: fields?.receive_email,
      // captcha: captcha,
    };

    try {
      setLoading(true);
      const responseuserform = RegisterService.regData(data);
      responseuserform
        .then((response) => {
          var valueUserform = response?.data?.status;
          setValue_userform(valueUserform);
          setLoading(false);

          // toast.success("Registered Successfully!", {
          //   position: "top-center",
          //   autoClose: 1500,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "colored",
          // });
          secureLocalStorage.setItem(
            "registerform",
            JSON.stringify({
              name: fields.name,
              lname: fields.lname,
              phone: fields.phone,
              emailid: fields.emailid,
              store_location: fields.store_location,
              sales_name_f: fields.sales_name_f,
              sales_name_l: fields.sales_name_l,
              question: fields.question,
              comments: fields?.comments,
              preferred_charity: fields?.preferred_charity,
              recommend: fields?.recommend,
              receive_email: fields?.receive_email,
              // captcha: captcha,
            })
          );
          localStorage.removeItem("return_page");
          localStorage.setItem("isRegister", true);

          navigate("/register/registration-thankyou");

          if (value_userform?.includes("redirect")) {
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          if (
            err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
          ) {
            toast.error(err?.response?.data?.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }

          let errorData = {};
          errorData["name"] =
            err?.response?.data?.message &&
            err?.response?.data?.message?.fname &&
            err?.response?.data?.message?.fname[0];
          errorData["lname"] =
            err?.response?.data?.message &&
            err?.response?.data?.message?.lname &&
            err?.response?.data?.message?.lname[0];
          errorData["phone"] =
            err?.response?.data?.message &&
            err?.response?.data?.message?.phone &&
            err?.response?.data?.message?.phone[0];
          errorData["emailid"] =
            err?.response?.data?.message &&
            err?.response?.data?.message?.email &&
            err?.response?.data?.message?.email[0];
          errorData["store_location"] =
            err?.response?.data?.message &&
            err?.response?.data?.message?.store_location &&
            err?.response?.data?.message?.store_location[0];
          errorData["sales_name_f"] =
            err?.response?.data?.message &&
            err?.response?.data?.message?.sales_name_f &&
            err?.response?.data?.message?.sales_name_f[0];
          errorData["sales_name_l"] =
            err?.response?.data?.message &&
            err?.response?.data?.message?.sales_name_l &&
            err?.response?.data?.message?.sales_name_l[0];
          errorData["question"] =
            err?.response?.data?.message &&
            err?.response?.data?.message?.question &&
            err?.response?.data?.message?.question[0];
          errorData["preferred_charity"] =
            err?.response?.data?.message &&
            err?.response?.data?.message?.preferred_charity &&
            err?.response?.data?.message?.preferred_charity[0];
          errorData["receive_email"] =
            err?.response?.data?.message &&
            err?.response?.data?.message?.receive_email &&
            err?.response?.data?.message?.receive_email[0];

          errorData["checkederrorcaptcha"] =
            err?.response?.data?.message &&
            err?.response?.data?.message?.captcha &&
            err?.response?.data?.message?.captcha[0];
          errorData["checkederrortwo"] =
            err?.response?.data?.message &&
            err?.response?.data?.message?.size &&
            err?.response?.data?.message?.size[0];

          saveErrors(errorData);
          setTimeout(() => {
            if (
              err?.response?.data?.message?.store_location &&
              !err?.response?.data?.message?.fname &&
              !err?.response?.data?.message?.lname &&
              !err?.response?.data?.message?.email &&
              !err?.response?.data?.message?.phone &&
              !err?.response?.data?.message?.sales_name_f &&
              !err?.response?.data?.message?.sales_name_l
            ) {
              $("#store_location").focus().select();
            } else if (
              err?.response?.data?.message?.question &&
              !err?.response?.data?.message?.store_location &&
              !err?.response?.data?.message?.fname &&
              !err?.response?.data?.message?.lname &&
              !err?.response?.data?.message?.email &&
              !err?.response?.data?.message?.phone &&
              !err?.response?.data?.message?.sales_name_f &&
              !err?.response?.data?.message?.sales_name_l
            ) {
              $("#question").focus().select();
            } else {
              ref?.current?.focus();
            }
          }, 200);
        });
    } catch (err) {
      alert(err);
      if (err.response.status === 429 || err.response.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
      let errorData = {};
      errorData["name"] = err?.response?.data?.message?.fname[0];
      errorData["lname"] = err?.response?.data?.message?.lname[0];
      errorData["phone"] = err?.response?.data?.message?.phone[0];
      errorData["emailid"] = err?.response?.data?.message?.emailid[0];
      errorData["store_location"] =
        err?.response?.data?.message?.store_location[0];
      errorData["sales_name_f"] = err?.response?.data?.message?.sales_name_f[0];
      errorData["sales_name_l"] = err?.response?.data?.message?.sales_name_l[0];
      errorData["question"] = err?.response?.data?.message?.question[0];
      errorData["preferred_charity"] =
        err?.response?.data?.message?.preferred_charity[0];
      errorData["receive_email"] =
        err?.response?.data?.message?.receive_email[0];

      errorData["checkederrorcaptcha"] =
        err?.response?.data?.message?.captcha[0];
      saveErrors(errorData);
    }
  };

  // function onCaptchaChange(value) {
  //   if (value === null) {
  //     setCaptcha(false);
  //   } else {
  //     setCaptcha(true);
  //   }
  // }
  // function NetworkError() {
  //   setCaptchaError(
  //     "Captcha failed to load due to a network issue. Please refresh the page."
  //   );
  // }

  const Select2Field = ({ name, label, options, value }) => {
    const selectRef = useRef();

    useLayoutEffect(() => {
      const $select = $(selectRef.current);

      $select.select2();

      $select.on("change", (event) => {
        const selectedValue = $(event.target).val();
        setFields((prevValue) => {
          return {
            ...prevValue,
            [name]: selectedValue,
          };
        });
      });

      return () => {
        $select.select2("destroy");
        $select.off("change");
      };
    }, []);

    return (
      <>
        <select
          ref={selectRef}
          name={name}
          className="form-select register-select2"
          value={value || ""}
          id={name}
        >
          <option value="" disabled={true} selected={true}>
            Select {label}
          </option>
          {options.map((opt, index) => (
            <option key={index} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <section className="form-body-center form-section-bg new-register">
        <div className="container">
          <div className="row gy-4 align-items-center">
            <div className="col-12 col-md-12 col-xl-12">
              <div className="register-logo">
                <img
                  src={logo}
                  alt="city-logo.png"
                  className="img-fluid"
                  width="360"
                  height="118"
                />
              </div>
            </div>
            <div className="col-12 col-md-12 col-xl-12">
              <div className="d-flex justify-content-center text-bg-primary top-bg">
                <div className="col-12 col-xl-12 text-center">
                  <h2 className="form-heading">
                    Register your guest for their $25 Mastercard Reward Card!
                  </h2>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-12 col-xl-12">
              <div className="card border-0 rounded-4 p-0">
                <div className="card-body p-3 p-md-4 p-xl-5">
                  <form
                    onSubmit={submituserRegistrationForm}
                    name="userRegistrationForm"
                  >
                    <div className="row gy-3 overflow-hidden">
                      {/* <div className="col-md-4 col-sm-12">
                        <div className="form-floating mb-3">
                          <i className="zmdi zmdi-account-circle icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            name="firstName"
                            placeholder="Sales Associate Name"
                            required
                          />

                          <label className="form-label">
                            Sales Associate Name
                          </label>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12">
                        <div className="form-floating mb-3">
                          <i className="zmdi zmdi-pin icon-position"></i>

                          <select className="form-control" name="place">
                            <option value="place"></option>

                            <option value="Street">Street</option>

                            <option value="District">District</option>

                            <option value="City">City</option>
                          </select>

                          <label className="form-label form-label-select">
                            Store Location
                          </label>
                        </div>
                      </div> 

                       <div className="col-md-4 col-sm-12">
                        <div className="form-floating mb-3">
                          <i className="zmdi zmdi-store icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Customer Address"
                            required
                          />

                          <label className="form-label">Customer Address</label>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <div className="form-floating mb-3">
                          <i className="zmdi zmdi-city icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="City "
                            required
                          />

                          <label className="form-label">City </label>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12">
                        <div className="form-floating mb-3">
                          <i className="zmdi zmdi-home icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Apartment/Suite #"
                            required
                          />

                          <label className="form-label">
                            Apartment/Suite # (Optional)
                          </label>
                        </div>
                      </div>
                             <div className="col-md-4 col-sm-12">
                        <div className="form-floating mb-3">
                          <i className="zmdi zmdi-smartphone-code icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Zip Code"
                            required
                          />

                          <label className="form-label">Zip Code</label>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <div className="form-floating mb-3">
                          <i className="zmdi zmdi-my-location icon-position"></i>

                          <select className="form-control" name="place">
                            <option value="place"></option>

                            <option value="Street">State</option>

                            <option value="District">District</option>

                            <option value="City">City</option>
                          </select>

                          <label className="form-label form-label-select">
                            State
                          </label>
                        </div>
                      </div>
                      
                      
                      */}

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-account-o icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            style={
                              errors.name ? { border: "1px solid red" } : null
                            }
                            name="name"
                            placeholder="Customer First Name"
                            ref={errors.name ? ref : null}
                            value={fields.name || ""}
                            autoFocus=""
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            Customer First Name
                            <span className="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">{errors.name}</div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating">
                          <i className="zmdi zmdi-account-o icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            name="lname"
                            style={
                              errors.lname && !errors.name
                                ? { border: "1px solid red" }
                                : null
                            }
                            placeholder="Customer Last Name"
                            value={fields.lname || ""}
                            ref={errors.lname && !errors.name ? ref : null}
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            Customer Last Name
                            <span className="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.lname && !errors.name ? errors.lname : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-local-phone icon-position"></i>

                          <input
                            type="text"
                            name="phone"
                            style={
                              errors.phone && !errors.lname && !errors.name
                                ? { border: "1px solid red" }
                                : null
                            }
                            className="form-control"
                            placeholder="Customer Phone#"
                            value={fields.phone || ""}
                            ref={
                              errors.phone && !errors.lname && !errors.name
                                ? ref
                                : null
                            }
                            onChange={handleChange}
                            onKeyUp={onlyNumbers}
                            onPaste={pasted}
                          />

                          <label className="form-label">
                            Customer Phone #
                            <span className="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.phone && !errors.lname && !errors.name
                            ? errors.phone
                            : null}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-email-open icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            name="emailid"
                            ref={
                              errors.emailid &&
                              !errors.phone &&
                              !errors.lname &&
                              !errors.name
                                ? ref
                                : null
                            }
                            style={
                              errors.emailid &&
                              !errors.phone &&
                              !errors.lname &&
                              !errors.name
                                ? { border: "1px solid red" }
                                : null
                            }
                            placeholder="Customer Email"
                            value={fields.emailid || ""}
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            Customer Email
                            <span className="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.emailid &&
                          !errors.phone &&
                          !errors.lname &&
                          !errors.name
                            ? errors.emailid
                            : null}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-account-circle icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            style={
                              errors.sales_name_f &&
                              !errors.emailid &&
                              !errors.phone &&
                              !errors.lname &&
                              !errors.name
                                ? { border: "1px solid red" }
                                : null
                            }
                            name="sales_name_f"
                            placeholder="Employee First Name"
                            ref={
                              errors.sales_name_f &&
                              !errors.emailid &&
                              !errors.phone &&
                              !errors.lname &&
                              !errors.name
                                ? ref
                                : null
                            }
                            value={fields.sales_name_f || ""}
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            Employee First Name
                            <span className="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.sales_name_f &&
                          !errors.emailid &&
                          !errors.phone &&
                          !errors.lname &&
                          !errors.name
                            ? errors.sales_name_f
                            : null}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-account-circle icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            name="sales_name_l"
                            placeholder="Employee Last Name"
                            style={
                              errors.sales_name_l &&
                              !errors.sales_name_f &&
                              !errors.emailid &&
                              !errors.phone &&
                              !errors.lname &&
                              !errors.name
                                ? { border: "1px solid red" }
                                : null
                            }
                            ref={
                              errors.sales_name_l &&
                              !errors.sales_name_f &&
                              !errors.emailid &&
                              !errors.phone &&
                              !errors.lname &&
                              !errors.name
                                ? ref
                                : null
                            }
                            value={fields.sales_name_l || ""}
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            Employee Last Name
                            <span className="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.sales_name_l &&
                          !errors.sales_name_f &&
                          !errors.emailid &&
                          !errors.phone &&
                          !errors.lname &&
                          !errors.name
                            ? errors.sales_name_l
                            : null}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-pin icon-position"></i>

                          {storeLoading ? (
                            <Skeleton count={1} height="48px" />
                          ) : (
                            <Select2Field
                              name="store_location"
                              label="Location"
                              options={locations}
                              value={fields["store_location"] || ""}
                            />
                          )}

                          <label className="form-label form-label-select">
                            Store Location
                            {/* <span className="gl-form-asterisk"></span> */}
                          </label>
                        </div>
                        <div className="errorMsg">
                          {!storeLoading && !locations.length
                            ? "The store location failed to load. Please refresh the page."
                            : errors.store_location &&
                              !errors.sales_name_l &&
                              !errors.sales_name_f &&
                              !errors.emailid &&
                              !errors.phone &&
                              !errors.lname &&
                              !errors.name
                            ? errors.store_location
                            : null}
                        </div>
                      </div>
                      <div className="col-md-8 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-walk icon-position"></i>

                          <Select2Field
                            name="question"
                            label="Option"
                            options={question}
                            value={fields["question"] || ""}
                          />

                          <label className="form-label form-label-select">
                            What mattress was your preferred mattress brand?
                            <span className="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg d-inline">
                          {errors.question &&
                          !errors.store_location &&
                          !errors.sales_name_l &&
                          !errors.sales_name_f &&
                          !errors.emailid &&
                          !errors.phone &&
                          !errors.lname &&
                          !errors.name
                            ? errors.question
                            : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-account-circle icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            name="recommend"
                            placeholder="Recommended by RSA"
                            value={fields.recommend || ""}
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            Recommended by RSA
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-comments icon-position"></i>

                          <textarea
                            type="text"
                            className="form-control"
                            placeholder="Comments"
                            name="comments"
                            value={fields.comments || ""}
                            style={{ minHeight: "80px" }}
                            onChange={handleChange}
                          ></textarea>

                          <label className="form-label">
                            Additional Comments
                          </label>
                        </div>
                      </div>
                      {/* <div className="col-md-4 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-walk icon-position"></i>

                          <Select2Field
                            name="preferred_charity"
                            label="Preferred Charity"
                            options={charity}
                            value={fields["preferred_charity"] || ""}
                          />

                          <label className="form-label form-label-select">
                            Preferred Charity
                            <span className="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg d-inline">
                          {errors.preferred_charity &&
                          !errors.question &&
                          !errors.store_location &&
                          !errors.sales_name_l &&
                          !errors.sales_name_f &&
                          !errors.emailid &&
                          !errors.phone &&
                          !errors.lname &&
                          !errors.name
                            ? errors.preferred_charity
                            : null}
                        </div>
                      </div> */}
                      <div className="col-md-12  col-sm-12 mb-0 mt-0">
                        <fieldset
                          className={`checkbox-group ${
                            errors.preferred_charity &&
                            !errors.store_location &&
                            !errors.sales_name_l &&
                            !errors.sales_name_f &&
                            !errors.emailid &&
                            !errors.phone &&
                            !errors.lname &&
                            !errors.name
                              ? "danger-error"
                              : ""
                          }`}
                        >
                          <legend className="checkbox-group-legend">
                            Preferred Charity
                            <span className="text-danger"> *</span>
                          </legend>
                          <div className="card-list">
                            <label className="checkbox-wrapper">
                              <input
                                type="radio"
                                name="preferred_charity"
                                className="checkbox-input"
                                value="Cystic Fibrosis Foundation"
                                onChange={handleChange}
                              />
                              <span className="checkbox-tile">
                                <span className="checkbox-icon">
                                  <img
                                    src={cff}
                                    alt="logos..."
                                    className="imgcolor img-fluid"
                                  />
                                </span>
                                <span className="checkbox-label">
                                  Cystic Fibrosis Foundation
                                </span>
                              </span>
                            </label>
                          </div>
                          <div className="card-list">
                            <label className="checkbox-wrapper">
                              <input
                                type="radio"
                                name="preferred_charity"
                                className="checkbox-input"
                                value="Local Food Bank"
                                onChange={handleChange}
                              />
                              <span className="checkbox-tile">
                                <span className="checkbox-icon">
                                  <img
                                    src={localFood}
                                    alt="logos..."
                                    className="imgcolor img-fluid"
                                  />
                                </span>
                                <span className="checkbox-label">
                                  Local Food Bank
                                </span>
                              </span>
                            </label>
                          </div>
                          <div className="card-list">
                            <label className="checkbox-wrapper">
                              <input
                                type="radio"
                                name="preferred_charity"
                                className="checkbox-input"
                                value="Make – A – Wish"
                                onChange={handleChange}
                              />
                              <span className="checkbox-tile">
                                <span className="checkbox-icon">
                                  <img
                                    src={makeWish}
                                    alt="logos..."
                                    className="imgcolor img-fluid"
                                  />
                                </span>
                                <span className="checkbox-label">
                                  Make – A – Wish
                                </span>
                              </span>
                            </label>
                          </div>
                        </fieldset>

                        <div className="errorMsg">
                          {errors.preferred_charity &&
                          !errors.question &&
                          !errors.store_location &&
                          !errors.sales_name_l &&
                          !errors.sales_name_f &&
                          !errors.emailid &&
                          !errors.phone &&
                          !errors.lname &&
                          !errors.name
                            ? errors.preferred_charity
                            : null}
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="receive_email"
                            value={fields.receive_email}
                            onChange={handleChange}
                            id="myCheck"
                          />
                          <label className="form-check-label" htmlFor="myCheck">
                            Sign up to receive email updates from Furniture Mart
                            USA & Ashley.
                          </label>
                          <div className="errorMsg">
                            {errors.receive_email &&
                            !errors.preferred_charity &&
                            !errors.question &&
                            !errors.store_location &&
                            !errors.sales_name_l &&
                            !errors.sales_name_f &&
                            !errors.emailid &&
                            !errors.phone &&
                            !errors.lname &&
                            !errors.name
                              ? errors.receive_email
                              : null}
                          </div>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      {/* <div className="col-md-8 col-sm-12 mb-3 mb-md-0 mt-0">
                        <div className="overflow-hider">
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                            onChange={onCaptchaChange}
                            onErrored={NetworkError}
                            asyncScriptOnLoad={() => {
                              setCaptchaError("");
                            }}
                            ref={recaptchaRef}
                          />
                        </div>

                        <div className="errorMsg">
                          {captchaError !== "" ? (
                            <>
                              {captchaError}{" "}
                              {captchaError !== "Loading" && (
                                <>
                                  Or{" "}
                                  <button
                                    type="button"

                                    className="btn btn-danger btn-sm"
                                    onClick={reloadCaptcha}
                                  >
                                    Reset reCAPTCHA
                                  </button>
                                </>
                              )}
                            </>
                          ) : !errors.preferred_charity &&
                            !errors.question &&
                            !errors.store_location &&
                            !errors.sales_name_l &&
                            !errors.sales_name_f &&
                            !errors.emailid &&
                            !errors.phone &&
                            !errors.lname &&
                            !errors.name ? (
                            errors.checkederrorcaptcha
                          ) : null}
                        </div>
                      </div> */}

                      <div className="col-md-4 col-sm-12 mt-0">
                        <div className="d-grid">
                          <button
                            className="btn btn-primary btn-lg"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="alert alert-info alert-info-custom">
                          Limit 1 per household, while supplies last. Some
                          restrictions apply, see store for detail.
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>

      {/* <Footer2 /> */}
    </>
  );
}
export default RegisterForm;
